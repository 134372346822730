// * Bootstrap Select
$color-red-error: rgb(185, 74, 72) ;
$color-green-success: #28a745;
$color-grey-arrow: $dark ;

$width-default: 220px ; // 3 960px-grid columns
$zindex-select-dropdown: 1060 ; // must be higher than a modal background (1050)

//** Placeholder text color
$input-color-placeholder: $ct-black ;
$input-alt-color-placeholder: $white ;

.selectWrap{
    .dropdown-menu{
        background-color: $dark !important;
    }
    .ct-option{
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        text-align: center;
        color: $white !important;
        background-color: $dark !important;
        &.disabled{
            color: $secondary !important;
        }
        &:hover{
            background-color: lighten($dark, 15%) !important;
        }
    }
    .select--N-HoverStyle{
        .btn,
        .btn:focus,
        .btn:hover{
            border-radius: 0;
            background-color: transparent;
        }
    }
    .select--noArrow{
        .dropdown-toggle::after{
            display: none;
        }
    }
}
.bootstrap-select {
    & > select{
        opacity: 0;
    }
}