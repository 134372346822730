// based on bs4 stretched
.hoverAct{
    min-height: 250px;
    $transition: .3s;
    .hoverAct__bg,
    .hoverAct__text,
    .hoverAct__bg:hover,
    .hoverAct__bg::after,
    .hoverAct__bg:hover::after,
    .hoverAct__text:hover{
        transition: $transition;
    }
    img{
        min-height: 100%;
        object-fit: cover;
    }

    .hoverAct__bg{
        position: relative;
        &::after{
            content: '';
            width: 100%;
            height: 30%;
            @include position(absolute, 0, 0, null, null);
            opacity: 0.8;
            background: linear-gradient(180deg, rgba(51,51,51,.75) 0%, transparent 100%);
            z-index: 3;
        }
    }
    .hoverAct__text{
        opacity: 0.2;
    }
    &:hover{
        .hoverAct__bg::after{
            opacity: 1;
            height: 100%;
        }
        .hoverAct__text{
            opacity: 1;
        }
    }
    .stretched-link::after{
        z-index: 5;
        
    }
}