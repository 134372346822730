.contact_header{
    &.section-bg-image{
        @include background-image(null);
    }
}

.contact_form{
    &.section-bg-image{
        @include background-image(null, 25%);
    }
}

.contact_form{
    .card{
        transform:translateY(-40%);
        @include media-breakpoint-down(md){
            transform:translateY(-20%);
        }
    }
}