.rent_feature,
.rent_type{
    .card-text{
        font-weight: bold;
    }
}

.rent_feature,
.rent_roomFeature{
    .card-size{
        max-width: 120px;
        height: auto;
    }
}

.rent_header{
    @include background-image(null, 60%)
}

.rent_feature{
    @include background-image(null, 25%);
}

.rent_type,
.rent_usage{
    .rent_img--height{
        min-height: 300px;
        @include media-breakpoint-down(md){
            min-height: 150px;
        }
    }
    .rent_img--center{
        @include background-image(null, center);
    }
    .rent_img--right{
        @include background-image(null, (75%, 50%));
    }
    .rent_img--left{
        @include background-image(null, (25%, 50%));
    }
}

.rent_roomFeature{
    @include background-image(null, (45%,center));
}

.rent_onePrice{
    .card-size{
        max-width: 125px;
        height: auto;
        @include media-breakpoint-down(sm){
            max-width: 80px;
        }
    }
}