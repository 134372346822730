.btn.btn-hoverToDark{
    $background: $dark;
    $transition: $transition-base;
    $scale: 1.2;
    transition: $transition;
    position: relative;
    z-index: 1;
    &::before{
        content: '';
        width: 100%;
        height: 100%;
        z-index: -1;
        background-color: transparent;
        transition: $transition;
        border-radius: $btn-border-radius;
        @include position(absolute, 0, 0, null, null)
    }
    &:hover{
        background-color: $background;
        border-color: $background;
        color: $white;
        &::before{
            background-color: $background;
            transition: $transition;
            transform: scale($scale);
        }
    }
}

.btn.btn-border-0{
    border-radius: 0;
    &.btn-hoverToDark::before{
        border-radius: 0;
    }
}

.btn.btn-primary{
    color: $white;
}