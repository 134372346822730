// * 自定義區域
$ct-gold:#C8A264;
$ct-gray:  #DCDCDC;
$ct-gray-lighter:#ECECEC;
$ct-black:  #333333;
$ct-footer: #E3E3E3;
$transparent: transparent;

// * 覆蓋bs-variables.scss
$primary: $ct-gold;
$dark: $ct-black;
$muted:   $ct-gray;

$navbar-padding-y: 1.5rem;

$font-family-base: "Segoe UI", Roboto, sans-serif, '微軟正黑體';

$border-radius: 5px;

$modal-inner-padding:               2rem;
$modal-header-padding-x:            2rem;
$modal-header-padding-y:            2rem;
$modal-content-border-radius:       0;
$modal-header-border-width:         0;
$modal-footer-border-width:         0;
$modal-backdrop-opacity:            .8;

$input-border-color: $muted;

$box-shadow-sm: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
$box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
$box-shadow-lg: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;