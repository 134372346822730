.loading{
    background-color: rgba(255,255,255);
    .img-size{
        max-width: 100px;
        @include media-breakpoint-down(sm){
            max-width: 100px;
        }
    }
    .img--animate__pulse{
        animation-iteration-count: infinite;
        @extend %animate__animated;
        @extend %animate__pulse;
    }
    &.animate__fadeOut{
        @extend %animate__animated;
        @extend %animate__fadeOut;
    }
}