.qa_header{
    &.section-bg-image{
        @include background-image(null, 35%)
    }
}

.qa_collapse{
    $fa-plus-transition: .3s;
    .fa-plus{
        transition: $fa-plus-transition;
        transform: rotate(45deg);
    }
    .collapsed .fa-plus{
        transition: $fa-plus-transition;
        transform: rotate(0deg);
    }
}