// * animate.css
@keyframes pulse{
    0%{transform:scaleX(1)}
    50%{transform:scale3d(1.05,1.05,1.05)}
    to{transform:scaleX(1)}
}

@keyframes fadeOutDown{
    0%{opacity:1}
    to{opacity:0;transform:translate3d(0,100%,0)}
}

@keyframes fadeOut{
    0%{opacity:1}
    to{opacity:0}
}

// * custom
@keyframes type3 {
    0% {
        width: 0;
    }
    1% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
@keyframes blink {
    50% {
        border-color: transparent;
    }
}