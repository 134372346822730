@mixin position($position, $top, $right, $bottom, $left){
    position: $position;
    top: $top;
    right: $right;
    left: $left;
    bottom: $bottom;
}

@mixin background-line-oblique($line: $primary, $bg: transparent, $deg: 135, $size: 30, $opacity: 0.3) {
    opacity: $opacity;
    background-size: #{$size}px #{$size}px;
    background-color: $bg;
    background-image: repeating-linear-gradient(#{$deg}deg, $line 0, $line 1px, $bg 0, $bg 50%);
}

@mixin background-points($bg:transparent, $points: #fff, $opacity:0.7, $pattern-size: 18px, $point-size: 2px){
    background-color: $bg;
    opacity: $opacity;
    background-image: radial-gradient($points $point-size, $bg $point-size);
    background-size: $pattern-size $pattern-size;
}

@mixin background-image($url, $position: center, $size: cover, $repeat: no-repeat){
    background-image: $url;
    background-position: $position;
    background-size: $size;
    background-repeat: $repeat;
}