.input-group.date[data-provide="datepicker"]{
    .form-control{
        cursor: pointer !important;
    }
}

$dp-primary-bg:                $primary;
$dp-primary-color:             $white;

$dp-date-normal-bg:          #F2F2F2;
$dp-date-normal-text:          $dark;
$dp-disabled:                  darken($dp-date-normal-bg, 7%);
$dp-not-this-month--date:      lighten($dp-date-normal-text, 45%);
$dp-not-this-month--span:    #999;

$dp-normal--bg-hover:        #eee;

$dp-select--bg:                darken($dp-date-normal-bg, 30%);
$dp-select--color:           #fff;
//====
$datepicker-dropdown-padding:           0;
$datepicker-px:                         0.65rem;
$datepicker-py:                         0.25rem;
$datepicker-th-px:                      $datepicker-px *1;
$datepicker-th-py:                      $datepicker-py *2;
$datepicker-td-px:                      $datepicker-px *1;
$datepicker-td-py:                      $datepicker-py *1.5;
$datepicker-week-px:                    $datepicker-px;
$datepicker-week-py:                    $datepicker-py *1.5;

$datepicker-border-radius:              0;
$datepicker-border-radius-menu:         #{$datepicker-border-radius}px;
$datepicker-border-radius-thtd:         #{$datepicker-border-radius}px;
$datepicker-border-radius-span:         #{$datepicker-border-radius}px;
$datepicker-border-radius-daterange:    #{$datepicker-border-radius}px;

$datepicker-color:                      $dp-date-normal-text;
$datepicker-dropdown-bg:                $white;
$datepicker-dropdown-border-color:      transparent;
$datepicker-thead-bg:                   $dark;
$datepicker-thead-color:                $primary;
$datepicker-tbody-bg:                   $dp-date-normal-bg;
$datepicker-week-bg:                    darken($datepicker-tbody-bg, 5%);
$datepicker-week-color:                 $dark;

$datepicker-table-font-weight:          bold;

$datepicker-arrow-bg:                    $dp-date-normal-bg;
$datepicker-arrow-shadow:                darken($dp-date-normal-bg, 30%);
$datepicker-arrow-size:                  6;

$datepicker-date-hover-bg:             #eee;
$datepicker-date-not-this-month-color:   $dp-not-this-month--date;
$datepicker-date-disabled-color:         $dp-disabled;
$datepicker-date-highlight-bg:         #d9edf7;
// #fde19a
$datepicker-today-bg:                    $dp-date-normal-bg;
$datepicker-today-border:                $dp-date-normal-bg;
$datepicker-today-color:                 $dp-primary-bg;

$datepicker-today-bg-hover:              lighten($datepicker-today-bg, 10%);
$datepicker-today-bg-active:             darken($datepicker-today-bg, 10%);
$datepicker-today-color-hover-hover:     $dark;
$datepicker-today-color-active-hover:    $white;

$datepicker-range-bg: #eee;

$datepicker-range-today-bg:              darken($datepicker-today-bg, 10%);
$datepicker-range-today-border:          $datepicker-range-today-bg;
$datepicker-range-today-bg-hover:        lighten($datepicker-today-bg, 20%);
$datepicker-range-today-bg-active:       darken($datepicker-today-bg, 20%);

$datepicker-selected-bg:                 $dp-select--bg;
$datepicker-selected-color:              $dp-select--color;
$datepicker-selected-bg-hover:           lighten($datepicker-selected-bg, 10%);
$datepicker-selected-bg-active:          darken($datepicker-selected-bg, 10%);

$datepicker-active-bg:                   $dp-primary-bg;
$datepicker-active-color:                $dp-primary-color;
$datepicker-active-bg-hover:             lighten($datepicker-active-bg, 10%);
$datepicker-active-bg-active:            darken($datepicker-active-bg, 10%);

// * 選擇月份的顏色
$datepicker-span-bg-hover:               $dp-normal--bg-hover;
$datepicker-span-bg-disabled:            $dp-disabled;
$datepicker-span-active-bg:              $dp-primary-bg;
$datepicker-span-active-color:           $dp-primary-color;
$datepicker-span-active-bg-hover:        lighten($datepicker-span-active-bg, 10%);
$datepicker-span-active-bg-active:       darken($datepicker-span-active-bg, 10%);

$datepicker-span-not-this-month-color:   $dp-not-this-month--span;

// * 選擇 thead 的顏色
$datepicker-thead-bg-hover:              lighten($datepicker-thead-bg, 5%);
$datepicker-tfoot-bg-hover:              $datepicker-thead-bg-hover;

$datepicker-addon-bg:                   #eee;
$datepicker-addon-border:               #ccc;