.equipment_header{
    @include background-image(null, 80%);
}

.equipment_intro{
    .equipment_img--height{
        min-height: 300px;
        @include media-breakpoint-down(md){
            min-height: 150px;
        }
    }
    .equipment_intro_img--left{
        @include background-image(null, 25%);
    }
    .equipment_intro_img--center{
        @include background-image(null, center);
    }
    .equipment_intro_img{
        @include background-image(null, (75%, 100%));
    }
}