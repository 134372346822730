.input-group.with-icon{
    .input-group-prepend{
        .input-group-text{
            background-color: transparent;
            // border-right-color: transparent;
            // padding-top: 0;
            // padding-bottom: 0;
        }
    }
    .form-control{
        border-left-color: transparent;
    }
}