/*!
 * Datepicker for Bootstrap v1.9.0 (https://github.com/uxsolutions/bootstrap-datepicker)
 *
 * Licensed under the Apache License v2.0 (http://www.apache.org/licenses/LICENSE-2.0)
 */

.datepicker {
    padding: $datepicker-dropdown-padding;
    border-radius: $datepicker-border-radius-menu;
    direction: ltr;
}

.datepicker-inline {
    width: 220px;
}
.datepicker-rtl {
    direction: rtl;
    &.dropdown-menu{
        left: auto;
    }
    table tr td span{
        float: right;
    }
}

.datepicker-dropdown {
    background-color: $datepicker-dropdown-bg;
    border-color: $datepicker-dropdown-border-color;
    top: 0;
    left: 0;
    &:before,
    &:after{
        content: '';
        display: inline-block;
        border-top: 0;
        position: absolute;
    }
    &:before{
        border-left: #{$datepicker-arrow-size +1}px solid transparent;
        border-right: #{$datepicker-arrow-size +1}px solid transparent;
        border-bottom: #{$datepicker-arrow-size +1}px solid $datepicker-arrow-shadow;
        border-bottom-color: rgba(0, 0, 0, 0.2);
    }
    &:after{
        border-left: #{$datepicker-arrow-size}px solid transparent;
        border-right: #{$datepicker-arrow-size}px solid transparent;
        border-bottom: #{$datepicker-arrow-size}px solid $datepicker-arrow-bg;
    }
}

// * 小三角形
.datepicker-dropdown.datepicker-orient-{
    &left:before{
        left: #{$datepicker-arrow-size}px;
    }
    &left:after{
        left: #{$datepicker-arrow-size+1}px;
    }
    &right:before{
        right: #{$datepicker-arrow-size}px;
    }
    &right:after{
        right: #{$datepicker-arrow-size+1}px;
    }
    &bottom:before{
        top: -#{$datepicker-arrow-size+1}px;
    }
    &bottom:after{
        top: -#{$datepicker-arrow-size}px;
    }
    &top:before,
    &top:after{
        bottom: -#{$datepicker-arrow-size}px;
        border-bottom: 0;
        border-top: #{$datepicker-arrow-size}px solid $datepicker-arrow-bg;
    }
}

.datepicker {
    table {
        margin: 0;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        font-weight: $datepicker-table-font-weight;
    }
    table,
    thead,
    tfoot {
        background-color: $datepicker-thead-bg;
        color: $datepicker-thead-color;
    }
    table{
        color: $datepicker-color;
    }
    tbody{
        background-color: $datepicker-tbody-bg;
    }
    td,
    th {
        text-align: center;
        width: 20px;
        height: 20px;
        border-radius: $datepicker-border-radius-thtd;
        border: none;
    }
    th{
        padding-left: $datepicker-th-px;
        padding-right: $datepicker-th-px;
        padding-top: $datepicker-th-py;
        padding-bottom: $datepicker-th-py;
    }
    td{
        padding-left: $datepicker-td-px;
        padding-right: $datepicker-td-px;
        padding-top: $datepicker-td-py;
        padding-bottom: $datepicker-td-py;
    }
    // * week th
    .dow{
        background-color: $datepicker-week-bg;
        color: $datepicker-week-color;
        padding-left: $datepicker-week-px;
        padding-right: $datepicker-week-px;
        padding-top: $datepicker-week-py;
        padding-bottom: $datepicker-week-py;
    }
}


.table-striped .datepicker table tr td,
.table-striped .datepicker table tr th {
    background-color: transparent;
}

.datepicker table tr td{
    &.day:hover,
    &.day.focused {
        background: $datepicker-date-hover-bg;
        cursor: pointer;
    }
    &.old,
    &.new {
        color: $datepicker-date-not-this-month-color;
    }
    &.disabled,
    &.disabled:hover {
        background: none;
        color: $datepicker-date-disabled-color;
        cursor: default;
    }
    &.highlighted {
        background: $datepicker-date-highlight-bg;
        border-radius: 0;
    }
    &.today,
    &.today:hover,
    &.today.disabled,
    &.today.disabled:hover {
        background-color: $datepicker-today-bg;
        // background-image: -moz-linear-gradient(to bottom, #fdd49a, #fdf59a);
        // background-image: -ms-linear-gradient(to bottom, #fdd49a, #fdf59a);
        // background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fdd49a), to(#fdf59a));
        // background-image: -webkit-linear-gradient(to bottom, #fdd49a, #fdf59a);
        // background-image: -o-linear-gradient(to bottom, #fdd49a, #fdf59a);
        // background-image: linear-gradient(to bottom, #fdd49a, #fdf59a);
        background-repeat: repeat-x;
        // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fdd49a', endColorstr='#fdf59a', GradientType=0);
        border-color: $datepicker-today-border;
        // filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
        color: $datepicker-today-color;
    }
    &.today:hover,
    &.today:hover:hover,
    &.today.disabled:hover,
    &.today.disabled:hover:hover,
    &.today:active,
    &.today:hover:active,
    &.today.disabled:active,
    &.today.disabled:hover:active,
    &.today.active,
    &.today:hover.active,
    &.today.disabled.active,
    &.today.disabled:hover.active,
    &.today.disabled,
    &.today:hover.disabled,
    &.today.disabled.disabled,
    &.today.disabled:hover.disabled,
    &.today[disabled],
    &.today:hover[disabled],
    &.today.disabled[disabled],
    &.today.disabled:hover[disabled] {
        background-color: $datepicker-today-bg-hover;
    }
    &.today:active,
    &.today:hover:active,
    &.today.disabled:active,
    &.today.disabled:hover:active,
    &.today.active,
    &.today:hover.active,
    &.today.disabled.active,
    &.today.disabled:hover.active {
        background-color: $datepicker-today-bg-active;
    }
    &.today:hover:hover {
        color: $datepicker-today-color-hover-hover;
    }
    &.today.active:hover {
        color: $datepicker-today-color-active-hover;
    }
    &.range,
    &.range:hover,
    &.range.disabled,
    &.range.disabled:hover {
        background: $datepicker-range-bg;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
    }
    &.range.today,
    &.range.today:hover,
    &.range.today.disabled,
    &.range.today.disabled:hover {
        background-color: $datepicker-range-today-bg;
        // background-image: -moz-linear-gradient(to bottom, #f3c17a, #f3e97a);
        // background-image: -ms-linear-gradient(to bottom, #f3c17a, #f3e97a);
        // background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f3c17a), to(#f3e97a));
        // background-image: -webkit-linear-gradient(to bottom, #f3c17a, #f3e97a);
        // background-image: -o-linear-gradient(to bottom, #f3c17a, #f3e97a);
        // background-image: linear-gradient(to bottom, #f3c17a, #f3e97a);
        // background-repeat: repeat-x;
        // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3c17a', endColorstr='#f3e97a', GradientType=0);
        border-color: $datepicker-range-today-border;
        // border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
        // filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
    }
    &.range.today:hover,
    &.range.today:hover:hover,
    &.range.today.disabled:hover,
    &.range.today.disabled:hover:hover,
    &.range.today:active,
    &.range.today:hover:active,
    &.range.today.disabled:active,
    &.range.today.disabled:hover:active,
    &.range.today.active,
    &.range.today:hover.active,
    &.range.today.disabled.active,
    &.range.today.disabled:hover.active,
    &.range.today.disabled,
    &.range.today:hover.disabled,
    &.range.today.disabled.disabled,
    &.range.today.disabled:hover.disabled,
    &.range.today[disabled],
    &.range.today:hover[disabled],
    &.range.today.disabled[disabled],
    &.range.today.disabled:hover[disabled] {
        background-color: $datepicker-range-today-bg-hover;
    }
    &.range.today:active,
    &.range.today:hover:active,
    &.range.today.disabled:active,
    &.range.today.disabled:hover:active,
    &.range.today.active,
    &.range.today:hover.active,
    &.range.today.disabled.active,
    &.range.today.disabled:hover.active {
        background-color: $datepicker-range-today-bg-active;
    }
    &.selected,
    &.selected:hover,
    &.selected.disabled,
    &.selected.disabled:hover {
        background-color: $datepicker-selected-bg;
        // background-image: -moz-linear-gradient(to bottom, #b3b3b3, #808080);
        // background-image: -ms-linear-gradient(to bottom, #b3b3b3, #808080);
        // background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#b3b3b3), to(#808080));
        // background-image: -webkit-linear-gradient(to bottom, #b3b3b3, #808080);
        // background-image: -o-linear-gradient(to bottom, #b3b3b3, #808080);
        // background-image: linear-gradient(to bottom, #b3b3b3, #808080);
        // background-repeat: repeat-x;
        // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3b3b3', endColorstr='#808080', GradientType=0);
        border-color: $datepicker-selected-bg;
        // border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
        // filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
        color: $datepicker-selected-color;
        // text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    }
    &.selected:hover,
    &.selected:hover:hover,
    &.selected.disabled:hover,
    &.selected.disabled:hover:hover,
    &.selected:active,
    &.selected:hover:active,
    &.selected.disabled:active,
    &.selected.disabled:hover:active,
    &.selected.active,
    &.selected:hover.active,
    &.selected.disabled.active,
    &.selected.disabled:hover.active,
    &.selected.disabled,
    &.selected:hover.disabled,
    &.selected.disabled.disabled,
    &.selected.disabled:hover.disabled,
    &.selected[disabled],
    &.selected:hover[disabled],
    &.selected.disabled[disabled],
    &.selected.disabled:hover[disabled] {
        background-color: $datepicker-selected-bg-hover;
    }
    &.selected:active,
    &.selected:hover:active,
    &.selected.disabled:active,
    &.selected.disabled:hover:active,
    &.selected.active,
    &.selected:hover.active,
    &.selected.disabled.active,
    &.selected.disabled:hover.active {
        background-color: $datepicker-selected-bg-active;
    }
    &.active,
    &.active:hover,
    &.active.disabled,
    &.active.disabled:hover {
        background-color: $datepicker-active-bg;
        // background-image: -moz-linear-gradient(to bottom, #08c, #0044cc);
        // background-image: -ms-linear-gradient(to bottom, #08c, #0044cc);
        // background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#08c), to(#0044cc));
        // background-image: -webkit-linear-gradient(to bottom, #08c, #0044cc);
        // background-image: -o-linear-gradient(to bottom, #08c, #0044cc);
        // background-image: linear-gradient(to bottom, #08c, #0044cc);
        // background-repeat: repeat-x;
        // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#08c', endColorstr='#0044cc', GradientType=0);
        border-color: $datepicker-active-bg;
        // border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
        // filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
        color: $datepicker-active-color;
        // text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    }
    &.active:hover,
    &.active:hover:hover,
    &.active.disabled:hover,
    &.active.disabled:hover:hover,
    &.active:active,
    &.active:hover:active,
    &.active.disabled:active,
    &.active.disabled:hover:active,
    &.active.active,
    &.active:hover.active,
    &.active.disabled.active,
    &.active.disabled:hover.active,
    &.active.disabled,
    &.active:hover.disabled,
    &.active.disabled.disabled,
    &.active.disabled:hover.disabled,
    &.active[disabled],
    &.active:hover[disabled],
    &.active.disabled[disabled],
    &.active.disabled:hover[disabled] {
        background-color: $datepicker-active-bg-hover;
    }
    &.active:active,
    &.active:hover:active,
    &.active.disabled:active,
    &.active.disabled:hover:active,
    &.active.active,
    &.active:hover.active,
    &.active.disabled.active,
    &.active.disabled:hover.active {
        background-color: $datepicker-active-bg-active;
    }

    span {
        display: block;
        width: 23%;
        height: 54px;
        line-height: 54px;
        float: left;
        margin: 1%;
        cursor: pointer;
        border-radius: $datepicker-border-radius-span;
        &:hover,
        &.focused {
            background: $datepicker-span-bg-hover;
        }
        &.disabled,
        &.disabled:hover {
            background: none;
            color: $datepicker-span-bg-disabled;
            cursor: default;
        }
        &.active,
        &.active:hover,
        &.active.disabled,
        &.active.disabled:hover {
            background-color: $datepicker-span-active-bg;
            // background-image: -moz-linear-gradient(to bottom, #08c, #0044cc);
            // background-image: -ms-linear-gradient(to bottom, #08c, #0044cc);
            // background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#08c), to(#0044cc));
            // background-image: -webkit-linear-gradient(to bottom, #08c, #0044cc);
            // background-image: -o-linear-gradient(to bottom, #08c, #0044cc);
            // background-image: linear-gradient(to bottom, #08c, #0044cc);
            // background-repeat: repeat-x;
            // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#08c', endColorstr='#0044cc', GradientType=0);
            border-color: $datepicker-span-active-bg;
            // border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
            // filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
            color: $datepicker-span-active-color;
            // text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
        }
        &.active:hover,
        &.active:hover:hover,
        &.active.disabled:hover,
        &.active.disabled:hover:hover,
        &.active:active,
        &.active:hover:active,
        &.active.disabled:active,
        &.active.disabled:hover:active,
        &.active.active,
        &.active:hover.active,
        &.active.disabled.active,
        &.active.disabled:hover.active,
        &.active.disabled,
        &.active:hover.disabled,
        &.active.disabled.disabled,
        &.active.disabled:hover.disabled,
        &.active[disabled],
        &.active:hover[disabled],
        &.active.disabled[disabled],
        &.active.disabled:hover[disabled] {
            background-color: $datepicker-span-active-bg-hover;
        }
        &.active:active,
        &.active:hover:active,
        &.active.disabled:active,
        &.active.disabled:hover:active,
        &.active.active,
        &.active:hover.active,
        &.active.disabled.active,
        &.active.disabled:hover.active {
            background-color: $datepicker-span-active-bg-active;
        }
        .old,
        .new {
            color: $datepicker-span-not-this-month-color;
        }
    }
    
}

.datepicker .datepicker-switch {
    width: 145px;
}
.datepicker .datepicker-switch,
.datepicker .prev,
.datepicker .next {
    cursor: pointer;
    &:hover{
        background: $datepicker-thead-bg-hover;
    }
}

.datepicker tfoot tr th {
    &:hover{
        background: $datepicker-tfoot-bg-hover;
    }
}
.datepicker .prev.disabled,
.datepicker .next.disabled {
    visibility: hidden;
}
.datepicker .cw {
    font-size: 10px;
    width: 12px;
    padding: 0 2px 0 5px;
    vertical-align: middle;
}

.input-append.date .add-on,
.input-prepend.date .add-on {
    cursor: pointer;
    i{
        margin-top: 3px;
    }
}


.input-daterange {
    input {
        text-align: center;
        &:first-child{
            border-radius: $datepicker-border-radius-daterange 0 0 $datepicker-border-radius-daterange;
        }
        &:last-child{
            border-radius: 0 $datepicker-border-radius-daterange $datepicker-border-radius-daterange 0;
        }
    }
    .add-on {
        display: inline-block;
        width: auto;
        min-width: 16px;
        height: 18px;
        padding: 4px 5px;
        font-weight: normal;
        line-height: 18px;
        text-align: center;
        // text-shadow: 0 1px 0 #fff;
        vertical-align: middle;
        background-color: $datepicker-addon-bg;
        border: 1px solid $datepicker-addon-border;
        margin-left: -5px;
        margin-right: -5px;
    }
}