/*
    * Section Title
        * z-index 寫在 z-indexs.scss
*/
// * text style
.section-title{
    font-weight: 900;
    font-size: 2.5rem;
    padding-left: 3rem;
    padding-right: 3rem;
    @include media-breakpoint-down(sm){
        padding-left: 0;
        padding-right: 0;
        font-size: 2rem;
    }
}

//* center title
.title--center{
    font-weight: bold;
    font-size: 4.5rem;
    @include media-breakpoint-down(md){
        font-size: 3rem;
    }
}

// * huge title
.title--hugeWrap{
    width: 100%;
    @include media-breakpoint-up(md){
        width: 61%;
    }
    .title-prepend{
        font-size: 38px;
        @include media-breakpoint-down(lg){
            font-size: 30px;
        }
        @include media-breakpoint-down(md){
            font-size: 22px;
        }
        @include media-breakpoint-down(sm){
            font-size: 28px;
        }
    }
    .text-huge{
        border-top: 5px solid $primary;
        border-bottom: 1px solid $primary;
        font-weight: 900;
        font-size: 100px;
        @include media-breakpoint-down(lg){
            font-size: 80px;
        }
        @include media-breakpoint-down(md){
            font-size: 60px;
        }
    }
}