.index_header{
    .header_deco{
        z-index: 1;
    }
    .header_content{
        z-index: 5;
    }
}

.index_about__content{
    &::before{
        z-index: 3;
    }
    .container{
        z-index: 5;
    }
}

.section-title-border{
    z-index: 5;
}

.bg-oblique,
.bg-oblique--light{
    z-index: 3;
    &::after{
        z-index: -1;
    }
    &::before{
        z-index: 1;
    }
    div{
        z-index: 5;
    }
}

.contact_btn--index{
    z-index: 1000;
}
