/*
    * Font Size & Responsive Font Size
    * ct-font-$  
        * .ct-font-1 = 12px / 0.75rem
        * .ct-font-2 = 16px / 1rem
        * .ct-font-3 = 20px / 1.25rem
        * .ct-font-4 = 24px / 1.5rem
        * .ct-font-5 = 28px / 1.75rem
        * .ct-font-6 = 32px / 2rem
        * .ct-font-7 = 36px / 2.25rem
        * .ct-font-8 = 40px / 2.5rem
        * .ct-font-9 = 44px / 2.75rem
        * .ct-font-10 = 48px / 3rem
    * .ct-font-{xs|sm|md|lg}-$
        * ex: ct-font-sm-1
*/
$custom-font-size-base: 1rem;
$font-size-range: 10;
@for $i from 1 through $font-size-range {
    .ct-font-#{$i} {
        font-size: $custom-font-size-base * 0.5 + $i * 0.25;
    }
}
//.custom-font-size-md-1
@each $breakpoint, $grid-breakpoint in $grid-breakpoints {
    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
        @for $i from 1 through $font-size-range {
            .ct-font-#{$breakpoint}-#{$i} {
                font-size: $custom-font-size-base * 0.5 + $i * 0.25;
            }
        }
    }
}
.ct-font--14px {
    font-size: 14em;
    font-size: 14rem;
}

/*
    * Height
    * 輸出 4 種 class ，各輸出 10 種 10vh, 20vh, 30vh ...~100vh
        * .min-vh-10 { min-height: 10vh }
        * .vh-10     { height: 10vh }
        * .min-{xs|sm|md|lg|...}-{10|20|30|...}
        * .vh-{10|20|30|...}
*/
$ct-vh-sizing: (
    'min-vh': 'min-height',
    'vh': 'height',
    );
$ct-vh-size-count: 10;
@each $class-name, $attr in $ct-vh-sizing {
    $count: 100 / $ct-vh-size-count;
    @for $i from 1 through $ct-vh-size-count {
        .#{$class-name}-#{$i* $count} {
            #{$attr}: #{$i* $count}vh;
        }
    }
}
@each $breakpoint, $grid-breakpoint in $grid-breakpoints {
    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
        @each $class-name, $attr in $ct-vh-sizing {
            $count: 100 / $ct-vh-size-count;
            @for $i from 1 through $ct-vh-size-count {
                .#{$class-name}-#{$breakpoint}-#{$i* $count} {
                    #{$attr}: #{$i* $count}vh;
                }
            }
        }
    }
}

@each $breakpoint, $grid-breakpoint in $grid-breakpoints {
    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
        $ct-width-size-count: 10;
        @for $i from 1 through $ct-width-size-count {
            .w-#{$breakpoint}-#{$i} {
                width: #{$i*10};
            }
        }
        .w-#{$breakpoint}-auto{
            width: auto;
        }
    }
}

/*
    * Border Width
        * 範圍:         1 ~ $border-width-range
        * .border-$  
*/
$border-width-range: 10;
@for $i from 1 through $border-width-range{
    .border-#{$i}{
        border-width: #{$i}px!important;
    }
}
@each $breakpoint, $grid-breakpoint in $grid-breakpoints {
    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
        @for $i from 0 through $border-width-range{
            .border-#{$breakpoint}-#{$i}{
                border-width: #{$i}px!important;
            }
        }
    }
}

/*
    * Background
        * z-index 寫在 z-indexs.scss
*/
.bg-oblique,
.bg-oblique--light{
    position: relative;
    &::after,
    &::before{
        content: '';
        width: 100%;
        height: 100%;
        @include position(absolute, 0, 0, null, null);
    }
    &::after{
        background-color: $dark;
    }
    &::before{
        @include background-line-oblique;
    }
}

.bg-oblique{
    &::after{
        background-color: $dark;
    }
    &::before{
        @include background-line-oblique;
    }
}
.bg-oblique--light{
    &::after{
        background-color: $ct-gray-lighter;
    }
    &::before{
        @include background-line-oblique($white);
    }
}

.section-bg-image{
    @include background-image(null)
}

/*
    * Rotate
*/
.rotate-90{
    transform: rotate(90deg);
}

/*
    * Rotate
*/
.ct-rounded-10{
    border-radius: 10px;
}

/*
    * Button
*/
.btn.btn-md-outline-primary{
    @include media-breakpoint-up(md){
        color: $primary !important;
        border-color: $primary;
        background-color: transparent;
    }
}
.btn.btn-lg-outline-primary{
    @include media-breakpoint-up(lg){
        color: $primary !important;
        border-color: $primary;
        background-color: transparent;
    }
}
.btn.btn-md-outline-primary,
.btn.btn-lg-outline-primary{
    &:hover{
        color: $white !important;
        background-color: $primary;
        border-color: $primary;
    }
}

.cursor-pointer{
    cursor: pointer;
}

.absolute-right-top-el{
    @include position(absolute, 0, 0, null, null)
}
.absolute-left-bottom-el{
    @include position(absolute, null, null, 0, 0)
}
.absolute-right-bottom-el{
    @include position(absolute, null, 0, 0, null)
}
.absolute-fluid-size{
    @include position(absolute, 0, 0, 0, 0)
}

$z-index-count: 10;
@for $i from 1 through $z-index-count{
    .z-index-#{$i}{
        z-index: #{$i}!important;
    }
}