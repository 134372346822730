.card-title{
    font-weight: bold;
}

.card--onebyone{
    img{
        border: 3px solid $white;
    }
    .card-body{
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .card-title{
        color: $primary;
    }
    .card-text{
        font-weight: bold;
    }
}