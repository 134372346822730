%active-style{
    content: '';
    height: 3px;
    width: 0;
    margin: auto;
    background-color: $primary;
    @include position(absolute, null, null, 0, null);
}

.nav-item.active .nav--hoverStyle::before,
.nav-item.active .nav--hoverStyle::after{
    @extend %active-style;
    width: 100%;
}
.nav--hoverStyle{
    $transition: .3s;
    &::before,
    &::after{
        transition: $transition;
        @extend %active-style;
    }
    &:hover::before,
    &:hover::after{
        transition: $transition;
        width: 100%;
    }
    &::before{
        right: 0;
    }
    &::after{
        left: 0;
    }
}

.fb{
    width: 2em;
    transition: .3s;
    &:hover{
        filter: brightness(.8);
    }
}