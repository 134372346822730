%animate__animated{
    animation-duration:1s;
    animation-duration:1s;
    animation-fill-mode:both
}
%animate__pulse{
    animation-name:pulse;
    animation-timing-function:ease-in-out
}

%animate__fadeOutDown{
    animation-name:fadeOutDown
}

%animate__fadeOut{
    animation-name:fadeOut
}