body{
    overflow-x: hidden;
}

/*
    * Header Decoration
        * z-index 寫在 z-indexs.scss
*/
.header_deco{
    height: 100%;
    // max-width: 100%;
    margin: auto;
    // transform: translateX(50%);
    @include position(absolute, 0, 0, null, null)
}


// * border
%ct-border{
    position: relative;
    &::after{
        content: '';
        margin: auto;
        background-color: $dark;
        @include position(absolute, null, 0, 0, 0)
    }
}

.section-title-border-dark,
.section-title-border-white,
.section-title-border-primary{
    $border-space: 3.5rem;
    padding-bottom: $border-space;
    margin-bottom: $border-space + 1rem;
    @extend %ct-border;
    &::after{
        width: 150px;
        height: 8px;
    }
}
.section-title-border-dark{
    &::after{
        background: none;
        background-color: $dark !important;
    }
}
.section-title-border-white{
    &::after{
        background: none;
        background-color: $white !important;
    }
}
.section-title-border-primary{
    &::after{
        background: none;
        background-color: $primary !important;
    }
}

.card-border-dark,
.card-border-primary{
    $border-space: 1.5rem;
    padding-bottom: $border-space;
    margin-bottom: $border-space;
    @extend %ct-border;
    &::after{
        height: 2px;
        width: 75%;
    }
}

.card-border-dark{
    &::after{
        background: none;
        background-color: $dark !important;
    }
}
.card-border-primary{
    &::after{
        background: none;
        background-color: $primary !important;
    }
}
