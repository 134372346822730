.index_header{
    &.section-bg-image{
        @include background-image(null, 40%);
        background-position: 40% !important;
    }
}

.landing_logo{
    min-width: 130px;
}

.header_content{
    position: relative;
}

$index-feature-fix-height: 5vh;
.index_feature{
    .card-size{
        width: auto;
        max-height: 110px;
        @include media-breakpoint-down(md){
            max-height: 80px;
        }
    }
    .card-title{
        font-weight: bold;
    }

    .deco-beyond{
        position: relative;
        &::before,
        &::after{
            content: '';
            width: 100%;
            height: $index-feature-fix-height;
            background-color: $primary;
            @include position(absolute, null, 0, null, 0);
        }
        &::before{
            top: 1px;
            transform: translateY(-99%);
        }
        &::after{
            bottom: 1px;
            transform: translateY(99%);
        }
    }
}

.index_header--fix{
    padding-top: $index-feature-fix-height;
    padding-bottom: $index-feature-fix-height;
}

.index_feature--fix{
    height: $index-feature-fix-height;
}

.index_roomTour{
    .card-body{
        @include media-breakpoint-down(md){
            padding-left: 0;
            padding-right: 0;
        }
    }
    .card-size{
        @include media-breakpoint-down(lg){
            width: 100%;    
        }
    }
    .card-text{
        @include media-breakpoint-up(lg){
            min-height: 180px;
        }
    }

}

.index_about{
    .index_about__content{
        position: relative;
        &::before{
            content: '';
            width: 130px;
            height: 180px;
            transform: translate(100%, -70%);
            @include background-points;
            @include position(absolute, 0, null, null, 0);
        }
    }
}